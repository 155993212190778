:root {
    /* Primary color variables */
    --primary-bg-color: #121212; /* Background color */
    --primary-text-color: #eeeeee; /* Text color */
    --hover-bg-color: #4f4f4f; /* Hover background color */
    --hover-transition: background-color 0.3s ease; /* Hover transition effect */
    --button-bg-color: #6c63ff; /* Button background color */
    --tooltips-bg-color: #6c63ff; /* Tooltip background color */
    --border-radius: 5px; /* Border radius for rounded elements */
    --table-header-bg-color: #333333; /* Table header background color */
    --delete-btn-bg-color: #ff4d4d; /* Delete button background color */
    --show-btn-bg-color: #00CC00; /* Show button background color */
    --metrics-bg-color: #2f2f2f;
    --group-header: #121212;
}

body {
    background-color: var(--primary-bg-color);
    color: var(--primary-text-color);
    font-family: 'Verdana', sans-serif;
}

/* Heading Styles */
h1, h2 {
    color: var(--primary-text-color);
}

/* Select Box Styles */
select {
    background-color: #2d2d2d;
    color: var(--primary-text-color);
    padding: 10px;
    border: none;
    border-radius: var(--border-radius);
    font-size: 16px;
    cursor: pointer;
}

/* Hover effect for select box */
select:hover {
    background-color: var(--hover-bg-color);
}

/* Focus effect for select box */
select:focus {
    outline: none;
}

/* Button Styles */
button {
    background-color: var(--button-bg-color);
    color: var(--primary-text-color);
    border: none;
    padding: 10px 20px;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
}

/* Button hover effect */
button:hover {
    background-color: var(--hover-bg-color);
}

/* List Styles */
ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

ul li {
    padding: 10px;
    transition: background-color 0.3s ease;
}

/* Hover effect for list items */
ul li:hover {
    background-color: var(--hover-bg-color);
    cursor: pointer;
}

/* Tooltip Styles */
.tooltip {
    background-color: var(--tooltips-bg-color);
    color: var(--tooltips-bg-color);
    padding: 10px;
    border-radius: var(--border-radius);
}

/* Component Containers */
.ResilienceMetricsAnalyser {
    background-color: var(--primary-bg-color);
    border-radius: 0px;
    width: 100%;
    height: 100%;
}

.effectiveness-curves-selection {
    display: flex;
    height: 25%;
    width: 100%;
}

/* Date input field styles */
input[type="date"] {
    background-color: var(--hover-bg-color);
    color: #ffffff;
    border: 3px solid var(--primary-bg-color);
    padding: 8px;
    border-radius: var(--border-radius);
    outline: none;
    transition: border-color 0.3s;
}

/* Focus effect for date input */
input[type="date"]:focus {
    border-color: #1e90ff;
}

/* Graph and map container styles */
.graph-config {
    display: flex;
    gap: 20px;
    align-items: center;
}

.graph-and-map-container {
    display: flex;
    flex: 1 1 100%;
    width: 100%;
}

.graphs {
    display: flex;
    flex: 1 1 100%;
}

.canvas {
    display: flex;
    height: 100%;
    width: 100%;
}

.map {
    display: flex;
    flex: 1 1 50%;
}

.leaflet-map {
    display: flex;
    height: 100%;
    width: 100%;
    filter: brightness(2.0);
    border-radius: var(--border-radius);
}

/* Resilience metrics selector */
.resilience-metrics-selector {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: left;
    gap: 10px;
}

/* Table Styles */
.table-theme1 {
    border-collapse: collapse;
    width: 100%;
}

.table-theme1 th {
    padding: 10px;
    text-align: left;
    background-color: var(--table-header-bg-color);
    color: white;
}

.table-theme1 th:first-child {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.table-theme1 th:last-child {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.table-theme1 td {
    padding: 8px;
    text-align: left;
}

.table-theme1 td .comment-input {
    width: 100%;
    background-color: #333;
    color: #ddd;
    border: 0;
    border-radius: var(--border-radius);
    padding: 4px;
    font-size: 14px;
    resize: vertical;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.table-theme1 td .comment-input:focus {
    outline: none;
    border-color: var(--hover-bg-color);
}

.table-theme1 td:first-child {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
}

.table-theme1 td:last-child {
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

/* Table Row Hover Effect */
.table-theme1 .clickable-row:hover {
    background-color: var(--hover-bg-color);
    cursor: pointer;
}

/* Specific result cell styles */
.table-theme1 td.result-cell {
    /* background-color: blue; */
    color: white;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    border-radius: var(--border-radius);
    padding: 10px;
    resize: vertical;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    text-align: center;
}

/* Delete Button Styles */
.table-delete-button {
    background-color: var(--delete-btn-bg-color);
    color: var(--primary-text-color);
    width: 100px;
}

.table-delete-button:hover {
    opacity: 0.9;
}

.table-container {
    margin-bottom: 20px;
    padding: 10px;
    border-radius: var(--border-radius);
    border: none;
    overflow: hidden;
}

.table-metrics {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: var(--border-radius);
}

.table-metrics td, .table-metrics th {
    background-color: var(--primary-bg-color);
    padding: 10px;
    text-align: left;
    border: none;
    color: var(--primary-text-color);
}

.table-metrics thead th {
    background-color: var(--table-header-bg-color); /* Jaune plus foncé pour l'en-tête */
    font-weight: bold;
}

.table-metrics thead tr {
    border-bottom: 2px solid #ccc;
}

/* Show/Hide Button Styles */
.table-show-hide-button {
    padding: 8px 12px;
    border: none;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    background-color: var(--show-btn-bg-color);
}

.btn-show {
    background-color: var(--show-btn-bg-color);
    color: var(--primary-text-color);
    width: 100px;
}

.btn-hide {
    background-color: var(--delete-btn-bg-color);
    color: var(--primary-text-color);
    width: 100px;
}

/* Popup Styles (SweetAlert2) */
.dark-mode-popup {
    background-color: #2c2c2c;
    color: var(--primary-text-color);
    border: 1px solid #444;
}

.dark-mode-popup .swal2-title {
    color: #f5f5f5;
}

.dark-mode-popup .swal2-content {
    color: #e0e0e0;
}

.dark-mode-popup .swal2-confirm {
    background-color: #d33;
    border: none;
}

.dark-mode-popup .swal2-cancel {
    background-color: #3085d6;
    border: none;
}

.dark-mode-popup .swal2-button {
    border-radius: 4px;
}

.dark-mode-popup .swal2-confirm:hover,
.dark-mode-popup .swal2-cancel:hover {
    filter: brightness(0.9);
}

.dark-mode-popup .swal2-icon {
    color: #ffcc00;
}

/* Range Input Styles */
.smoothing-factor {
    padding: 10px;
    display: flex;
    height: 5%;
}

/* Inverted range slider */
.smoothing-factor-slider {
    transform: scaleX(-1);
}

/* Range input styles */
input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 15rem;
}

input[type="range"]:focus {
    outline: none;
}

/* Webkit and Blink browsers (Chrome, Safari, Opera) */
input[type="range"]::-webkit-slider-runnable-track {
    background-color: #808080;
    border-radius: 0.5rem;
    height: 0.5rem;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    margin-top: -12px;
    background-color: #e0e0e0;
    height: 2rem;
    width: 1rem;
}

input[type="range"]:focus::-webkit-slider-thumb {
    border: 1px solid #053a5f;
    outline: 3px solid #053a5f;
    outline-offset: 0.125rem;
}

/* Firefox specific styles */
input[type="range"]::-moz-range-track {
    background-color: #053a5f;
    border-radius: 0.5rem;
    height: 0.5rem;
}

input[type="range"]::-moz-range-thumb {
    background-color: #5cd5eb;
    height: 2rem;
    width: 1rem;
}

input[type="range"]:focus::-moz-range-thumb {
    border: 1px solid #053a5f;
    outline: 3px solid #053a5f;
    outline-offset: 0.125rem;
}

.group-header {
    background-color: var(--group-header);
    cursor: pointer;
}

.clickable-row:hover {
    background-color: var(--hover-bg-color);
}


